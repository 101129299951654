import React from "react"
import { createStore } from "redux"
import { Provider } from "react-redux"

import { rootReducer } from "./src/reducer"

export default ({ element }) => {
  const store = createStore(rootReducer)
  return <Provider store={store}>{element}</Provider>
}
